<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 12:57:06
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-02-15 17:07:21
 * @Description: 
-->
<template>
  <div class="search">
    <el-button
      v-if="$attrs['addName']"
      class="butColor storkBut"
      @click="addClick"
      type="primary"
      size="small"
      >{{ $attrs["addName"] }}</el-button
    >
    <el-form
      ref="searchForm"
      class="searchForm"
      :model="searchForm"
      label-width="100px"
    >
      
      <el-form-item
        v-if="$attrs['seceltModel']"
        :label="$attrs['searchListInfo'].type"
      >
        <el-select v-model="searchForm.region" @change="searchFormType">
          <el-option :label="'全部'" value=""></el-option>
          <el-option
            v-for="self in $attrs['searchSelectList']"
            :key="self.id"
            :label="self.name"
            :value="self.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 时间选择 -->
      <el-form-item
        v-if="$attrs['timeModel']"
        :label="$attrs['searchListInfo'].time"
      >
        <el-date-picker
          v-model="searchForm.time"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <!-- 时间区间选择 -->
      <el-form-item
        v-if="$attrs['timeInterval']"
        :label="$attrs['searchListInfo'].timeInterval"
      >
        <el-date-picker
          @change="sectionChange"
          v-model="searchForm.timeInterval"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item :class="$attrs['searchListInfo'].type == '企业类型'?'marLefts':$attrs['searchListInfo'].type == '使用者名称'?'marLefts2':$attrs['searchListInfo'].name == '产品名称'?'marLefts2': ''" :label="$attrs['searchListInfo'].name">
        <el-input
          v-model="searchForm.name"
          :placeholder="$attrs['searchListInfo'].name"
        ></el-input>
        <el-button
          size="small"
          @click="searchBut"
          class="searchBut"
          type="primary"
        >搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchForm: {
        timeInterval: "",
        time: "",
        name: "",
        region: "",
      },
    };
  },
  methods: {
    addClick() {
      this.$emit("addClick");
    },
    searchBut() {
      this.$emit("searchBut", this.searchForm);
      // this.searchForm.region = '';
      // this.searchForm.timeInterval='';
    },
    sectionChange(val) {
      this.$forceUpdate();
      if(val == null) {
        this.$emit(
          "sectionChange",
          ['', ''],
          this.searchForm.name,
          this.searchForm.region
        );
      } else {
        this.$emit(
          "sectionChange",
          val,
          this.searchForm.name,
          this.searchForm.region
        );
      }
      // this.searchForm.region = '';
      // this.searchForm.name = '';
    },
    searchFormType(val) {
      this.$emit(
        "searchFormType",
        val,
        this.searchForm.name,
        this.searchForm.timeInterval
      );
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/style/colorPublic";
.butColor {
  // background: @but_color;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  // border: 1px solid #ccc;
  padding: 1vh;
  box-sizing: border-box;
}
.marLefts{
  margin-left: 50vw;
}
.marLefts2{
  margin-left: 10vw;
}
.marLefts3{
  margin-left: 40vw;
}


.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.searchBut {
  position: absolute;
  // right: 0;
  left: 12vw;
  top: 3px;
  height: 35px;
}
/* .searchForm /deep/ .el-input__inner {
  height: 5vh !important;
  line-height: 5vh !important;
}
.searchForm /deep/ .el-button--default {
  height: 5vh !important;
  padding: 9px 19px;
} */

@media screen and (max-width: 1500px) {
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    line-height: 5vh !important;
  }
  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }
  .searchBut {
    position: absolute;
    right: 0;
    top: 4px;
  }
}
</style>
