<!--
 * @Author: wangshuaiwei
 * @Date: 2022-01-04 09:40:57
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-02-21 16:04:42
 * @Description: 
-->
<template>
  <div class="enterpriseInfo">
    <div>
      <tableTemplate
        class="table"
        :searchSelectList="whether"
        @searchFormType="searchFormType"
        :searchListInfo="searchListInfo"
        @searchBut="searchBut"
        addName="新建记录"
        :loading="loading"
        :data="tableData"
        :columns="columns"
        @addClick="addClick"
        :seceltModel="seceltModel"
        :timeInterval="timeInterval"
        @sectionChange="sectionChange"
      >
        <template slot="avatar" slot-scope="scope">
          <div slot="avatar" class="operation">
            <span @click="infoOpen(scope.data.row)">详情</span>
            <span @click="eait(scope.data.row)">编辑</span>
            <span @click="delect(scope.data.row)">删除</span>
          </div>
        </template>
<template slot="processState" slot-scope="scope">
          <div class="operation">
            <img
              class="codeImg"
              @click="lookCode(scope.data.row)"
              src="../../../../assets/sourceScreen/codeImg.png"
              alt=""
            />
          </div>
        </template>
</tableTemplate>
<page @changePage="eventPage" @changeSize="eventPage" class="pageStyle" :pagination="pagination" />
<!-- 添加 -->
<el-dialog destroy-on-close :title="titles" :visible.sync="dialogVisible" width="35%">
    <el-form ref="addForm" :rules="rules" :model="addForm" label-width="140px">
        <el-form-item label="种植批次" prop="plantBatch">
            <el-select :disabled="batchInfo || batchInfoTwo" v-model="addForm.plantBatch" placeholder="请选择种植批次" @change="purchaserName">
                <el-option v-for="item in batchLists" :key="item.id" :label="item.batchNumber" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="作物名称" prop="name">
            <el-input disabled v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="采收批次" prop="recoveryBatch">
            <el-input :disabled="batchInfo" v-model="addForm.recoveryBatch"></el-input>
        </el-form-item>
        <el-form-item label="采购地块" prop="plotName">
            <el-input disabled v-model="addForm.plotName"></el-input>
        </el-form-item>
        <el-form-item label="采收负责人" prop="recoveryPrincipal">
            <el-select :disabled="batchInfo" v-model="addForm.recoveryPrincipal" @change="changePrincipal" placeholder="请选择采收负责人">
                <el-option v-for="item in industryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否加工" prop="processState">
            <el-radio-group :disabled="batchInfo" v-model="addForm.processState">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
        <el-form-item label="采收日期" prop="recoveryTime">
            <el-date-picker :disabled="batchInfo" value-format="yyyy-MM-dd HH:mm:ss" v-model="addForm.recoveryTime" type="datetime" placeholder="选择日期">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="种植批次采收结束" prop="plantBatchState" v-if="endHarvest">
            <el-radio-group :disabled="batchInfo" v-model="addForm.plantBatchState">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="收获量" prop="harvestYield">
            <el-input :disabled="batchInfo" v-model="addForm.harvestYield"></el-input>
        </el-form-item>
        <el-form-item label="收获量单位" prop="harvestYieldUnit">
            <el-input :disabled="batchInfo" v-model="addForm.harvestYieldUnit"></el-input>
        </el-form-item>
        <el-form-item label="上传监测报告" v-if="!batchInfo">
            <el-upload class="upload-demo" action="/imgUpdata" :limit="1" :file-list="fileList" :on-change="handleChange" :before-upload="beforeAvatarUpload" :on-exceed="handleExceed" :on-success="handleAvatarSuccess" :on-remove="onRemove">
                <div class="upFlieBox">
                    <i class="el-icon-upload"></i><span>点击上传</span>
                </div>
            </el-upload>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="!batchInfo">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button
            class="butColor"
            size="small"
            type="primary"
            @click="saveFrom(addForm, 'addForm')"
            >确 定</el-button
          >
        </span>
</el-dialog>
</div>
<!-- 二维码 -->
<el-dialog :modal="false" title="二维码" :visible.sync="codeShow" width="20%">
    <div v-loading="loadingCode" id="qrcode" ref="qrCodeUrl"></div>
    <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="download"
          >下载</el-button
        >
      </span>
</el-dialog>
</div>
</template>
<script>
    import tableTemplate from "@/components/soureUnit//tableTemplate.vue";
    import page from "@/components/page.vue";
    import {
        purchaseRecord
    } from "@/utils/required.js";
    import QRCode from "qrcodejs2";
    export default {
        components: {
            tableTemplate,
            page
        },
        data() {
            return {
                columns: [{
                        label: "作物名称",
                        prop: "name",
                        align: "center"
                    }, {
                        label: "采收批次",
                        prop: "recoveryBatch",
                        align: "center"
                    },
                    // { label: '种植批次', prop: 'plantBatch', align: 'center' },
                    {
                        label: "种植批次",
                        prop: "plantBatchNum",
                        align: "center"
                    }, {
                        label: "采收地块",
                        prop: "plotName",
                        align: "center"
                    },
                    // { label: '采收负责人', prop: 'recoveryPrincipal', align: 'center' },
                    {
                        label: "采收负责人",
                        prop: "recoveryPrincipalName",
                        align: "center"
                    }, {
                        label: "收获量",
                        prop: "harvestYield",
                        align: "left"
                    }, {
                        label: "收获量单位",
                        prop: "harvestYieldUnit",
                        align: "center"
                    }, {
                        label: "采收日期",
                        prop: "recoveryTime",
                        align: "left"
                    },
                    // { label: '是否加工', prop: 'processState', align: 'left' },
                    {
                        label: "二维码",
                        prop: "processState",
                        align: "center",
                        __slotName2: "processState"
                    }, {
                        label: "操作",
                        prop: "avatar",
                        align: "center",
                        __slotName: "avatar"
                    }
                ],
                tableData: [],
                addForm: {},
                dialogVisible: false,
                titles: "新建",
                codeShow: false,
                pagination: {
                    totalRow: 0,
                    page: 1,
                    size: 8
                },
                fileList: [],
                seceltModel: false,
                rules: purchaseRecord,
                enterpriseList: [
                    //企业信息
                    {
                        name: "有限公司",
                        value: 0
                    }, {
                        name: "无线公司",
                        value: 1
                    }
                ],
                industryList: [],
                whether: [{
                    name: "是",
                    id: 1
                }, {
                    name: "否",
                    id: 2
                }],
                batchLists: [],
                loading: true,
                loadingCode: true,
                searchListInfo: {
                    name: "作物名称",
                    type: "是否加工",
                    timeInterval: "采收日期"
                },
                timeInterval: true,
                batchInfo: false,
                batchInfoTwo: false,
                sourCodeUrl: "http://119.188.246.129:20023/#/h5",
                endHarvest: false,
                notOverBatch: [],
                allBatchList: [],
                saveThrottle: null,
                urls: '',
            };
        },
        mounted() {
            this.requestLsit();
            this.staffList(); //员工
            this.changeHarvesting();
            this.allHarvesting();
            this.saveThrottle = this.throttle(this.implement, 3000);
        },
        methods: {
            staffList() {
                this.$get("/enterpriseManage/querryStaff", {
                    page: 1,
                    size: 10000
                }).then(
                    res => {
                        if (res.data.state == "success") {
                            let {
                                datas
                            } = res.data;
                            this.industryList = datas;
                        }
                    }
                );
            },
            changeHarvesting() {
                //   批次
                //   this.$get('/plantingBatch/getPlantingBatchListPass', { page: 1, size: 10000 }).then((res) => {
                this.$get("/plantingBatch/getPlantingBatchListPassAndplantBatchState", {
                    page: 1,
                    size: 10000
                }).then(res => {
                    //   this.$get('/recoveryManage/querryRecoveryPlantBatchStateIsOver').then((res) => {
                    if (res.data.state == "success") {
                        let {
                            list
                        } = res.data.data;
                        this.batchLists = list;
                        this.notOverBatch = list;
                    }
                });
            },
            /**
             * @description: 全部批次
             * @return {*} 如果种植批次采收结束，当前批次不存在，修复回显问题。
             */
            allHarvesting() {
                this.$get("/plantingBatch/getPlantingBatchListPass", {
                    page: 1,
                    size: 10000
                }).then(res => {
                    if (res.data.state == "success") {
                        let {
                            datas
                        } = res.data;
                        this.allBatchList = datas;
                    }
                });
            },
            purchaserName(val) {
                this.addForm.plotName = this.batchLists.find(v => v.id == val).plotName;
                this.addForm.plotId = this.batchLists.find(v => v.id == val).plotId;
                this.addForm.name = this.batchLists.find(v => v.id == val).plantName;
                this.addForm.plantBatchNum = this.batchLists.find(
                    v => v.id == val
                ).batchNumber;
            },
            changePrincipal(val) {
                this.addForm.recoveryPrincipalName = this.industryList.find(
                    v => v.id == val
                ).name;
            },
            // purchaserName(val) {
            //   this.addForm.purchaser = this.industryList.find((v) => v.id == val).name;
            // },
            addClick() {
                this.dialogVisible = true;
                this.titles = "新建";
                this.batchInfo = false;
                this.batchInfoTwo = false;
                this.endHarvest = true;
                this.batchLists = this.notOverBatch;
                //   if (this.$refs['addForm'] != undefined) {
                this.$nextTick(() => {
                    this.addForm = {};
                    this.$refs["addForm"].resetFields();
                    this.fileList = [];
                });
                //   }
            },
            exitLoading() {
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            },
            download(){
                // var image = new Image()
                // // 解决跨域 Canvas 污染问题
                // image.setAttribute('crossOrigin', 'anonymous')
                // image.onload = function() {
                //     var canvas = document.createElement('canvas')
                //     canvas.width = image.width
                //     canvas.height = image.height

                //     var context = canvas.getContext('2d')
                //     context.drawImage(image, 0, 0, image.width, image.height)
                //     var url = canvas.toDataURL('image/png')
                //     var a = document.createElement('a')
                //     // 创建一个单击事件
                //     var event = new MouseEvent('click')
                //     a.href = url

                //     // 触发a的单击事件
                //     a.dispatchEvent(event)
                // }
                // image.src = this.urls;
                // this.$Message.info('下载成功');
            },
            requestLsit(name, processState, startTime, endTime) {
                // 企业信息
                this.$get("/recoveryManage/querryRecoveryRecord", {
                        page: this.pagination.page,
                        size: this.pagination.size,
                        name: name || "",
                        processState: processState || "",
                        startTime: startTime || "",
                        endTime: endTime || ""
                            // startTime: startTime || '',
                            // endTime: endTime || '',
                            // purchaser: purchaser || '',
                    })
                    .then(res => {
                        if (res.data.state == "success") {
                            //console.log(res);
                            let {
                                datas
                            } = res.data;
                            this.pagination.totalRow = res.data.count;
                            let tableVal = JSON.parse(JSON.stringify(datas));
                            tableVal.forEach(v => {
                                if (v.processState == 1) {
                                    v.processState = "加工";
                                } else if (v.processState == 2) {
                                    v.processState = "未加工";
                                }
                            });
                            this.tableData = tableVal;
                            //console.log(tableVal);
                            this.exitLoading();
                        }
                    })
                    .catch(err => {
                        this.exitLoading();
                        throw err;
                    });
            },
            //保存
            saveFrom() {
                this.saveThrottle();
            },
            // 执行
            implement() {
                let data = this.addForm,
                    dom = 'addForm';
                let url = "/recoveryManage/addRecoveryRecord";
                let inputsImg = []; //投入品
                let demoUrl = "https://public.half-half.cn/";
                if (this.titles == "新建") {
                    delete data.id;
                    this.fileList.forEach(v =>
                        inputsImg.push({
                            name: v.name,
                            response: v.response,
                            url: demoUrl + v.response.data.fileName
                        })
                    );
                } else if (this.titles == "修改") {
                    this.fileList.forEach(v =>
                        inputsImg.push({
                            name: v.name,
                            response: v.response,
                            url: demoUrl + v.response.data.fileName
                        })
                    );
                    url = "/recoveryManage/updateRecoveryRecord";
                    data.recoveryId = data.id;
                }
                data.detectionImg = JSON.stringify(inputsImg);

                //   data.inputsImg =demoUrl+ this.fileList[0].response.data.fileName;
                this.$refs[dom].validate(valid => {
                    if (valid) {
                        //种植批次是否采收结束
                        //   if (data.plantBatchState == 1) {
                        //   }
                        this.$post(url, data).then(res => {
                            // //console.log(res, '接口数据');
                            if (res.data.state == "success") {
                                this.dialogVisible = false;
                                this.$message.success(res.data.msg);
                                this.requestLsit();
                                this.changeHarvesting();
                            }
                        });
                    }
                });
            },

            // 种植批次采收结束
            plantingBatch() {},
            // 修改
            eait(data) {
                //console.log(data);
                this.titles = "修改";
                this.batchInfo = false;
                this.batchInfoTwo = true;
                this.dialogVisible = true;
                this.batchLists = this.allBatchList;
                this.addForm = JSON.parse(JSON.stringify(data));
                this.addForm.recoveryPrincipal = JSON.parse(
                    this.addForm.recoveryPrincipal
                );
                //   //console.log(data.recoveryPrincipal);
                if (this.addForm.plantBatch)
                    this.addForm.plantBatch = Number(this.addForm.plantBatch);
                if (data.detectionImg) {
                    this.fileList = JSON.parse(data.detectionImg);
                } else {
                    this.fileList = [];
                }

                this.judgeBatch(data.plantBatch, data.id);
            },
            /**
             * @description:
             * @param {*} plantBatchId 当前批次id
             * @param {*} id 当前列表采收id
             * 接口返回当前批次的最后一次采收id   只有最后一个采收记录才可以编辑 ’是否结束采收‘
             */
            async judgeBatch(plantBatchId, id) {
                let {
                    data: {
                        data: {
                            id: oldId
                        }
                    }
                } = await this.$get("/recoveryManage/fetchEndRecoveryRecordId", {
                    plantBatchId
                });
                if (oldId == id) return (this.endHarvest = true);
                return (this.endHarvest = false);
            },
            // 详情
            infoOpen(data) {
                this.titles = "详情";
                this.batchInfo = true;
                this.dialogVisible = true;
                this.batchLists = this.allBatchList;
                // this.$nextTick(() => (this.addForm = JSON.parse(JSON.stringify(data))));
                this.$nextTick(() => {
                    this.addForm = JSON.parse(JSON.stringify(data));
                    this.addForm.recoveryPrincipal = JSON.parse(
                        this.addForm.recoveryPrincipal
                    );
                    if (this.addForm.plantBatch)
                        this.addForm.plantBatch = Number(this.addForm.plantBatch);
                });
            },
            //删除
            delect(data) {
                this.$confirm(`此操作将永久删除 ${data.name} 信息, 是否继续?`, "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    })
                    .then(() => {
                        this.$get("/recoveryManage/delRecoveryRecord", {
                            recoveryId: data.id
                        }).then(res => {
                            if (res.data.state == "success") {
                                this.requestLsit();
                                this.loading = true;
                                this.$message.success(res.data.msg);
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除"
                        });
                    });
            },
            lookCode(data) {
                //console.log(data);
                let url = this.sourCodeUrl + `?active=sixth&id=${data.plantBatch}`;
                this.urls = url;
                //console.log(url);
                this.$nextTick(() => {
                    this.codeShow = true;
                    setTimeout(() => {
                        this.$refs.qrCodeUrl.innerHTML = "";
                        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                            text: url,
                            width: 130,
                            height: 130,
                            colorDark: "#000000",
                            colorLight: "#ffffff",
                            correctLevel: QRCode.CorrectLevel.H
                        });
                    }, 200);
                });
            },
            throttle(fn, delay) {
                let timeout, flag = false;
                return function() {
                    let args = arguments;
                    if (!flag) {
                        flag = true;
                        fn.apply(this, args);
                        timeout && clearTimeout(timeout);
                        timeout = setTimeout(() => {
                            flag = null;
                        }, delay)
                    }
                }
            },
            //
            searchBut(data) {
                this.requestLsit(data.name);
            },
            sectionChange(sectionChange, name) {
                this.requestLsit(name || "", "", sectionChange[0], sectionChange[1]);
            },
            searchFormType(val) {
                let types = this.whether.find(v => v.name == val).id;
                this.requestLsit("", types, "", "");
            },
            eventPage() {
                this.requestLsit();
            },
            handleChange(file, fileList) {
                this.fileList = fileList.slice(-1);
            },
            beforeAvatarUpload(file) {
                let {
                    name
                } = file;
                name = name.split(".")[1];
                if (name == "jpg" || name == "png") {
                    // this.uploadState = 0;
                    return true;
                }
                this.$message.info("请检查上传格式！");
                this.fileList = [];
                return false;
            },
            handleExceed() {
                this.$message.info("只能上传一个文件");
            },
            handleAvatarSuccess(res, file) {
                this.addForm.inputsImg =
                    "https://public.half-half.cn/" + res.data.fileName;
                //   //console.log(this.fileList);
            },
            onRemove(file, fileList) {
                this.addFormModel.inputsImg = "";
            }
        }
    };
</script>
<style scoped lang="less">
    @import "~@/style/colorPublic";
    .butColor {
        background: @but_color;
    }
    
    .enterpriseInfo {
        background: #fff;
        padding: 2vh;
        box-sizing: border-box;
    }
    
    .operation {
        display: flex;
        justify-content: space-around;
        span {
            cursor: pointer;
        }
    }
    
    .enterpriseInfo /deep/ .el-dialog__title {
        font-size: 1.5vh;
    }
    
    .table {
        height: 82vh;
        overflow-y: auto;
    }
    
    @media screen and (max-width: 1500px) {
        .table {
            height: 80vh;
            overflow-y: auto;
        }
    }
    
    .pageStyle {
        margin-top: 1vh;
    }
    
    .codeImg {
        width: 2vw;
        cursor: pointer;
    }
    
    #qrcode {
        /deep/ img {
            margin: 0 auto;
        }
    }
</style>