<!--
 * @Author: wangshuaiwei
 * @Date: 2021-12-14 10:53:46
 * @LastEditors: wangshuaiwei
 * @LastEditTime: 2022-03-01 16:01:22
 * @Description: 表格
-->
<template>
  <div class="templateTem">
    <search v-bind="$attrs" v-on="$listeners" />
    <el-table v-bind="$attrs" v-loading="$attrs['loading']" v-on="$listeners">
      <template v-for="item in columns">
        <el-table-column
          v-if="!item.typeIndex"
          :key="item.prop"
          v-bind="item"
          show-overflow-tooltip
        >
          <template v-if="item.__slotName || item.__slotName2" v-slot="scope">
            <slot :name="item.__slotName" :data="scope"></slot>
            <slot :name="item.__slotName2" :data="scope"></slot>
          </template>
        </el-table-column>
        <el-table-column
          v-if="item.typeIndex"
          label="序号"
          width="50"
          type="index"
          :key="item.prop"
          v-bind="item"
          show-overflow-tooltip
        >
        </el-table-column>
        <!-- :index="indexMethod" -->
        <!-- <el-table-column  v-if="item.__slotName2" :key="item.prop" v-bind="item" show-overflow-tooltip>
           <template  v-slot="scope">
            <slot :name="item.__slotName2"  :data="scope"></slot>
          </template>
        </el-table-column> -->
      </template>
    </el-table>
  </div>
</template>

<script>
import search from "@/components/soureUnit/tableSearch.vue";

export default {
  name: "miniTable",
  components: { search },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {},
};
</script>
<style scoped>
.templateTem /deep/ .has-gutter {
  border: 1px solid #ccc;
}
.templateTem /deep/ .has-gutter tr,
.templateTem /deep/ .has-gutter tr th {
  background: #f0f2f5 !important;
}
</style>
